<template>
  <section>
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="col-lg-12 col-md-12 mt-5 text-center">
          <h2 class="subTitle">Portfolio</h2>
        </div>
        <div class="col-lg-12 col-md-12 mb-5 text-center">
          <p class="capibDesc">They trust or trusted in Capibara services.</p>
        </div>
        <div class="col-lg-12 col-md-12 mb-5 text-center">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide>
              <img src="../assets/fsto.png" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/digi.png" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/agt.png" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/ecn.png" class="img-fluid" alt="" />
            </swiper-slide>

            <swiper-slide>
              <img src="../assets/ean.png" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/jfairs.png" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/cc.png" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/elec.png" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/nueva.png" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/rsco.png" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/siongold.png" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/cold.png" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/icd.png" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/hp.png" class="img-fluid" alt="" />
            </swiper-slide>
            <swiper-slide>
              <img src="../assets/tr.png" class="img-fluid" alt="" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "portfolio",
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 0,
        autoplay: {
          delay: 6600,
          disableOnInteraction: false,
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style>
.el-carousel__item h3 {
  color: #1a1a1a;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #1a1a1a;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #1a1a1a;
}
</style>
