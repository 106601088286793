<template>
  <div class="home">
       <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    <Main/>
  </div>
</template>

<script>
import Main from '@/components/Main.vue'
export default {
  name: 'Home',
  components: {
    Main
  }
}
</script>
