var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"capibSection"},[_c('div',{staticClass:"container mt-5 mb-5"},[_vm._m(0),_c('div',{staticClass:"row d-flex flex-row-center"},[_c('div',{staticClass:"col-lg-6 col-md-6 text-left"},[_c('div',{directives:[{name:"tilt",rawName:"v-tilt",value:({
            reverse: true,
            transition: true,
            speed: 500,
            perspective: 1200,
            easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
          }),expression:"{\n            reverse: true,\n            transition: true,\n            speed: 500,\n            perspective: 1200,\n            easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',\n          }"}],staticClass:"card turnRight card-custom"},[_vm._m(1)])]),_c('div',{staticClass:"col-lg-6 col-md-6 d-flex order-first align-items-center text-left"},[_c('img',{directives:[{name:"tilt",rawName:"v-tilt",value:({
            reverse: true,
            transition: true,
            speed: 500,
            perspective: 1200,
            easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
          }),expression:"{\n            reverse: true,\n            transition: true,\n            speed: 500,\n            perspective: 1200,\n            easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',\n          }"}],staticClass:"card-img-top img-fluid",attrs:{"src":require("../assets/me.png"),"alt":""}})])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row d-flex flex-row-reverse"},[_c('div',{staticClass:"col-lg-12 col-md-12 mt-5 mb-5 text-center"},[_c('h2',{staticClass:"subTitle"},[_vm._v("About "),_c('span',[_vm._v("Capibara")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-body m-5"},[_c('h5',[_vm._v("Why Capibara?")]),_c('p',{staticClass:"capibDesc"},[_vm._v(" This is Lorenzo Arellano, CEO of TheCapibaraWeb. Lorenzo is a Mexican web developer and entrepreneur born in the lands of Durango Mexico. He has an analogy in his mind about Capybaras that he applies every day with his clients, friends and coworkers. ")]),_c('p',{staticClass:"capibDesc"},[_vm._v(" The Capybara is the largest rodent species in the world, native to South America. These fantastic animals have a quirk very marked. THEY ARE VERY FRIENDLY! And that's where Lorenzo's analogy is born. ")]),_c('p',{staticClass:"capibDesc"},[_vm._v(" Capybaras can coexist in the same environment as a bird, be friends with jaguars, coexist with crocodiles and even carry their backs titi monkeys. Lorenzo brings this behavior to computer technology. The use of software has penetrated all industries. From agriculture, mining, finance to the space industry or more recently blockchain or metaverses. ")]),_c('p',{staticClass:"capibDesc mb-5"},[_vm._v(" Software can coexist in all sectors in a friendly way just as a Capybara can live with friendly species or even predators. ")])])}]

export { render, staticRenderFns }