<template>
  <!-- <section class="capibSection" :style="{'background-image': 'url(' + require('../assets/me.png') + ')'}"> -->
  <section class="capibSection">
    <div class="container mt-5 mb-5">
      <div class="row d-flex flex-row-reverse">
        <div class="col-lg-12 col-md-12 mt-5 mb-5 text-center">
          <h2 class="subTitle">Contact <span>Us</span></h2>
          <p class="capibDesc">
            You can contact the team through the following channels:
          </p>
        </div>

        <div class="col-lg-12 col-md-12 text-center">
          <span>
            <a class="nav-item mr-4" href="https://twitter.com/cryptomexicano" target="_blank"><i class="fab fa-twitter"></i></a>
            <a class="nav-item mr-4" href="https://www.facebook.com/thecapibaraweb/" target="_blank"><i class="fab fa-facebook"></i></a>
            <a class="nav-item " href="https://wa.link/hgcjxy" target="_blank"><i class="fab fa-whatsapp"></i></a>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "aboutSection",
};
</script>
