<template>
  <!-- <section class="capibSection" :style="{'background-image': 'url(' + require('../assets/me.png') + ')'}"> -->
  <section class="capibSection">
    <div class="container mt-5 mb-5">
      <div class="row d-flex flex-row-reverse">
        <div class="col-lg-12 col-md-12 mt-5 mb-5 text-center">
          <h2 class="subTitle">About <span>Capibara</span></h2>
        </div>
      </div>
      <div class="row d-flex flex-row-center">
        <div class="col-lg-6 col-md-6 text-left">
          <div
            class="card turnRight card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body m-5">
              <h5>Why Capibara?</h5>
              <p class="capibDesc">
                This is Lorenzo Arellano, CEO of TheCapibaraWeb. Lorenzo is a
                Mexican web developer and entrepreneur born in the lands of
                Durango Mexico. He has an analogy in his mind about Capybaras
                that he applies every day with his clients, friends and
                coworkers.
              </p>
              <p class="capibDesc">
                The Capybara is the largest rodent species in the world, native
                to South America. These fantastic animals have a quirk very
                marked. THEY ARE VERY FRIENDLY! And that's where Lorenzo's
                analogy is born.
              </p>
              <p class="capibDesc">
                Capybaras can coexist in the same environment as a bird, be
                friends with jaguars, coexist with crocodiles and even carry
                their backs titi monkeys. Lorenzo brings this behavior to
                computer technology. The use of software has penetrated all
                industries. From agriculture, mining, finance to the space
                industry or more recently blockchain or metaverses.
              </p>
              <p class="capibDesc mb-5">
                Software can coexist in all sectors in a friendly way just as a
                Capybara can live with friendly species or even predators.
              </p>
            </div>
          </div>
        </div>

        <div
          class="col-lg-6 col-md-6 d-flex order-first align-items-center text-left"
        >
          <img
            class="card-img-top img-fluid"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
            src="../assets/me.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "aboutSection",
};
</script>
