<template>
    <section class="mt-5">
        <div class="container mt-5 ">
            <div class="row">
                <div class="col-12 text-center d-flex align-items-center">
                    <div class="stack" style="--stacks: 3;">
                        <span style="--index: 0;">Coexisting in harmony with all species</span>
                        <span style="--index: 1;">Coexisting in harmony with all species</span>
                        <span style="--index: 2;">Coexisting in harmony with all species</span>
                      </div>
                    <img class="card-img-top img-fluid" src="../assets/capib.png" alt="">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'initialSection',
    }
</script>