<template>
  <section>
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="col-lg-12 col-md-12 mt-5 text-center">
          <h2 class="subTitle">Services</h2>
        </div>
        <div class="col-lg-12 col-md-12 mb-5 text-center">
            <p class="capibDesc">We are this universe at the same space time, also your ideas. Just ask us for one service.</p>
          </div>
        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch text-left mt-5"
        >
          <div
            class="card card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body text-center mt-3">
              <img src="../assets/web.png" width="180" />
              <h5 class="card-title text-dark red">Web Desing</h5>
              <p class="capibDesc text-left">
                We can create any website for you, your company or business. We
                have the best tools for your ideas to reach this reality.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch text-left mt-5"
        >
          <div
            class="card card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body text-center mt-3">
              <img src="../assets/business.png" width="180" />
              <h5 class="card-title red">Business Intelligence</h5>
              <p class="capibDesc text-left">
                Capibara's applied intelligence can be adapted to any type of
                industry. We offer the solution that you have been looking for
                so much.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch text-left mt-5"
        >
          <div
            class="card card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body text-center mt-3">
              <img src="../assets/mkt.png" width="180" />
              <h5 class="card-title red">Marketing Solutions</h5>
              <p class="capibDesc text-left">
                If your intention is to grow in the world of the Internet. Then
                you are visiting the correct website.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch text-left mt-5"
        >
          <div
            class="card card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body text-center mt-3">
              <img src="../assets/block.png" width="180" />
              <h5 class="card-title text-dark red">Blockchain Consulting</h5>
              <p class="capibDesc text-left">
                Capibara got on the Blockchain train since 2018, he can share
                the knowledge with you.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch text-left mt-5"
        >
          <div
            class="card card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body text-center mt-3">
              <img src="../assets/fin.png" width="180" />
              <h5 class="card-title red">Fintech Consulting</h5>
              <p class="capibDesc text-left">
                Learn about internet finances and the best methods to generate
                abundance with a few clicks.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch text-left mt-5"
        >
          <div
            class="card card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body text-center mt-3">
              <img src="../assets/more.png" width="180" />
              <h5 class="card-title red">And more...</h5>
              <p class="capibDesc text-left">
                Whatever your idea, whatever your industry, Capibara can help
                you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "aboutSection",
};
</script>
